.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: row;
}

.search-bar {
  width: 250px;
  height: 30px;
  font-size: 17px;
}

.search-button {
  width: 50px;
  margin-left: 7px;
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid rgb(35, 35, 35);
}

.search-button:hover {
  cursor: pointer;
}

.no-tag {
  color: red;
}

@media screen and (max-width: 760px) {
 
  .container {
    display: flex;
    flex-direction: row !important;
  
  }
}

