.button-style {
  height: 70px;
  width: 300px;
  align-items: center;
  border: none;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
  margin-top: 10px;
  font-size: 18px;
}

.button-style:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}
