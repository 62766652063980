body {
    background-color:white;
    color: black;
    max-width: 1000px;
    margin: auto;
}

.username-header {
    margin: auto;
    width: 30%;
    padding: 10px;
    color: black;
    display: flex;
}

.item-description {
    padding-left: 50px;
    padding-bottom: 20px;
    padding-top: 0px;
    color: black;
    text-align: left;
    width: 40%;
    font-size: 20px;
    box-shadow: 5px 10px #888888;
    flex-basis: 100px;
}

.availability {
    width: 50%;
    overflow-wrap: break-word;
}

.availability-container {
    display: flex;
    flex-direction: row;
}

.item-properties {
    font-weight: bold;
    color: black;
    font-size: 15px;
    padding: 30px;
}

.availabilities-header {
    color: black;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.item-header {
    color: black;
    padding: 30px;
}

.is-lost {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

@media screen and (max-width: 760px) {
 
    .availability-container {
      width: 75%;
      display: flex;
      flex-direction: column !important;
    }
    .item-description {
        box-shadow: none !important;
    }

    .availability {
        width: 100%;
        overflow-wrap: break-word !important;
    }
    
    .item-description {
        padding-left: 50px;
        padding-bottom: 20px;
        padding-top: 0px;
        color: black;
        text-align: left;
        width: 90%;
        font-size: 20px;
        box-shadow: 5px 10px #888888;
        flex-basis: 100px;
    }
  }