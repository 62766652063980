.main-container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.welcome-container {
  display: flex;
  flex-direction: row;
}

.buttons-container {
  margin-left: 50px;
  display: flex;
  flex-direction: column;
}

.welcome-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 760px) {
 
  .welcome-container {
    display: flex;
    flex-direction: column !important;
  
  }

  .buttons-container {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
  }
}
